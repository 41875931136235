import React from "react";
import "./message.css";

function Message(props) {
  const username = "username" ;
  const time = "12:45" ;
  
  return (
    <div className="msg-container">
      <div class="msg-info">
        <div class="msg-info-name">{username}</div>
        <div class="msg-info-time">{time}</div>
      </div>
      <p className="msg-text">{props.text}</p>
    </div>
  );
}

export default Message;
