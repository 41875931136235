// import React, {Component} from 'react'
// import Video from './video'
// import "./videos.css"

// class Videos extends Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       rVideos: [],
//       remoteStreams: [],
//       selectedVideo: null,
//       videoVisible: false,
//     }
//   }

//   componentWillReceiveProps(nextProps) {
//     if (this.props.remoteStreams !== nextProps.remoteStreams) {

//       const NoOfRemoteStreams = nextProps.remoteStreams.length

//       let selectedVideo = {}

//       if (NoOfRemoteStreams === 1)
//         selectedVideo = { selectedVideo: nextProps.remoteStreams[0] }
//       else {
//         selectedVideo = this.state.selectedVideo && nextProps.remoteStreams.filter(stream => stream.id === this.state.selectedVideo.id) || []

//         selectedVideo = selectedVideo.length ? {} : { selectedVideo: nextProps.remoteStreams[NoOfRemoteStreams-1] }
//       }

//       let _rVideos = nextProps.remoteStreams.map((rVideo, index) => {

//         const _videoTrack = rVideo.stream.getTracks().filter(track => track.kind === 'video')
//         // if (_videoTrack.length)
//         //   _videoTrack[0].onmute = () => {
//         //     alert('muted')
//         //   }

//         let video = _videoTrack && (
//           <Video
//             class='remote-video-me'
//             videoMuted={this.videoMuted}
//             videoType='remoteVideo'
//             videoStream={rVideo.stream}
//             frameStyle={{
//               // backgroundColor: '#ffffff12',
//               backgroundColor: 'yellow',
//               maxWidth: 120, maxHeight: 120,
//               borderRadius: 5,
//               float: 'left', margin: '0 3px'
//             }}
//             videoStyles={{
//               objectFit: 'cover',
//               borderRadius: 5,
//               width: 120, height: 120,
//               maxWidth: 120, maxHeight: 120,
//             }}
//           />
//         ) || <div></div>

//         return (
//           <div
//             className='rvideo-container'
//             id={rVideo.name}
//             onClick={() => this.switchVideo(rVideo)}
//             style={{ 
//               cursor: 'pointer', display: 'inline-block'
//             }}
//             key={index}
//           >
//             {video}
//           </div>
//         )
//       })

//       this.setState({
//         remoteStreams: nextProps.remoteStreams,
//         rVideos: _rVideos,
//         ...selectedVideo,
//       })
//     }
//   }

//   videoMuted = (rVideo) => {
//     const muteTrack = rVideo.getVideoTracks()[0]
//     const isSelectedVideo = rVideo.id === this.state.selectedVideo.stream.id
//     if (isSelectedVideo) {
//       this.setState({
//         videoVisible: !muteTrack.muted
//       })
//     }
//   }

//   switchVideo = (_video) => {
//     const muteTrack = _video.stream.getVideoTracks()[0]
//     this.setState({
//       selectedVideo: _video,
//       videoVisible: !muteTrack.muted
//     })
//   }

//   render() {
//     return (
//       <div>
//         {/* <Video
//         class='preview-video-me'
//           videoType='previewVideo'
//           frameStyle={{
//             // zIndex: 1,
//             position: 'fixed',
//             bottom: 0,
//             minWidth: '50%', minHeight: '50%',
//             backgroundColor: 'black'
//           }}
//           videoStyles={{
//             minWidth: '100%', minHeight: '100%',
//             visibility: this.state.videoVisible && 'visible' || 'hidden',
//           }}
//           videoStream={this.state.selectedVideo && this.state.selectedVideo.stream}
//         /> */}
//         <div
//         className='videos-container'
//           style={{
//             // zIndex: 3,
//             position: 'fixed',
//             padding: '6px 3px',
//             backgroundColor: 'rgba(0,0,0,0.3)',
//             maxHeight: 120,
//             top: 'auto',
//             right: 10,
//             left: 10,
//             bottom: 10,
//             overflowX: 'scroll',
//             whiteSpace: 'nowrap',
//             border : "2px solid red",
//           }}
//         >
//           { this.state.rVideos }
//         </div>
//       </div>
//     )
//   }

// }

// export default Videos

//-------------------------------------------------------------------------------------------------
//                           Side bar layout 
//-------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import Video from './video';
import "./videos.css";

class Videos extends Component {
  constructor(props) {
    super(props)

    this.state = {
      remoteStreams: [],
      selectedVideo: null,
      videoVisible: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.remoteStreams !== prevProps.remoteStreams) {
      const { remoteStreams } = this.props;
      const selectedVideo = remoteStreams.length > 0 ? { selectedVideo: remoteStreams[0] } : {};

      this.setState({
        remoteStreams,
        ...selectedVideo,
      });
    }
  }

  videoMuted = (rVideo) => {
    const muteTrack = rVideo.getVideoTracks()[0];
    const isSelectedVideo = rVideo.id === this.state.selectedVideo.stream.id;
    if (isSelectedVideo) {
      this.setState({
        videoVisible: !muteTrack.muted
      });
    }
  };

  switchVideo = (_video) => {
    const muteTrack = _video.stream.getVideoTracks()[0];
    this.setState({
      selectedVideo: _video,
      videoVisible: !muteTrack.muted
    });
  };

  render() {
    const { remoteStreams } = this.state;

    return (
      <div className='sidebar-container'>
        {remoteStreams.map((rVideo, index) => (
          <div
            className='rvideo-container'
            id={rVideo.name}
            onClick={() => this.switchVideo(rVideo)}
            key={index}
          >
            <Video
              class='remote-video-me'
              videoMuted={this.videoMuted}
              videoType='remoteVideo'
              videoStream={rVideo.stream}

              videoStyles={{
                width: 500,
                border: "1px solid yellow",
                display: "block",
                // margin: "auto",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              frameStyle={{
                width: 500,
                margin: 5,
                borderRadius: 5,
                backgroundColor: "Red",
                border: "2px solid white",
                width: "95%",
                // margin: "auto",
                height: "80%",
                borderRadius: "5px",
              }}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default Videos;


