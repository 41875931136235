import React, { Component } from "react";
import RecordRTC from "recordrtc";
import EmoticonBar from "./emoticonBar";

import "./callcontrolsStyles.css";

class CallControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disconnected: false,
      mic: true,
      camera: true,
      showEmoticonBar: false,
      showParticipantsTab: false, 
      stream: null,
      recorder: null,
      isRecording: false, // Add state to track recording status
    };
  }

  mutemic = (e) => {
    const stream = this.props.localStream;
    if (stream) {
      const audioTracks = stream.getAudioTracks();
      if (audioTracks.length > 0) {
        audioTracks[0].enabled = !audioTracks[0].enabled;
        this.setState({ mic: audioTracks[0].enabled });
      }
    }
  };

  mutecamera = (e) => {
    const stream = this.props.localStream;
    if (stream) {
      const videoTracks = stream.getVideoTracks();
      if (videoTracks.length > 0) {
        videoTracks[0].enabled = !videoTracks[0].enabled;
        this.setState({ camera: videoTracks[0].enabled });
      }
    }
  };

  handleLeaveCall = () => {
    // Call the handleDisconnect function passed from App.js
    this.props.handleDisconnect();
  };
  handleChat = () => {
    this.props.toggleChatBox();
  };
  toggleEmoticonBar = () => {
    this.setState((prevState) => ({
      showEmoticonBar: !prevState.showEmoticonBar,
    }));
  };
  
  toggleParticipantsTab = () => {
    this.setState((prevState) => ({
      showParticipantsTab: !prevState.showParticipantsTab, // Toggle the state
    }));
  };
  // Function to send emoji to remote participants via WebRTC
  sendEmojiToRemote = (emoji) => {
    // Implement WebRTC functionality to send emoji to remote participants
    // For example, use data channels to send emoji data to peers
    console.log("Sending emoji to remote participants:", emoji);
    this.props.sendEmojiToRemote(emoji);
    console.log("Sent");
    // Add your WebRTC logic here
  };

  handleScreenShare = () => {
    this.props.ScreenStream();
  };
  toggleParticipantsTab = () => {

  }
  // Function to start recording
  startRecording = async () => {
    try {
      const constraints = {
        video: {
          displaySurface: "screen",
        },
      };
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      // // Get screen capture stream
      // const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });

      // Get audio stream
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Merge screen capture and audio streams
      const mergedStream = new MediaStream([
        ...screenStream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);

      // Start recording with the merged stream
      const recorder = new RecordRTC(mergedStream, {
        type: "video",
      });
      recorder.startRecording();

      // Update component state
      this.setState({
        stream: mergedStream,
        recorder,
        isRecording: true,
      });
    } catch (error) {
      console.error("Error starting recording:", error);
      // You may want to display an error message to the user here
    }
  };

  // Function to stop recording
  stopRecording = () => {
    const { recorder, stream } = this.state;
    if (recorder) {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();
        // Create a new URL object from the blob
        const url = window.URL.createObjectURL(blob);
        // Create a new anchor element
        const a = document.createElement("a");
        // Set the href attribute of the anchor element to the URL
        a.href = url;
        // Set the download attribute of the anchor element to specify the filename
        a.download = "recorded-video.webm"; // Change the filename as needed
        // Append the anchor element to the document body
        document.body.appendChild(a);
        // Programmatically click the anchor element to trigger the download
        a.click();
        // Remove the anchor element from the document body
        document.body.removeChild(a);
        // Revoke the URL object to release the resources
        window.URL.revokeObjectURL(url);

        // Reset component state
        this.setState({
          stream: null,
          recorder: null,
          isRecording: false,
        });
      });
    }
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };
  render() {
    const { disconnected, mic, camera, showEmoticonBar, isRecording, showParticipantsTab } =
      this.state;

    if (disconnected) {
      // Display disconnect message or perform additional disconnect logic
      return <div>You have successfully Disconnected</div>;
    }

    return (
      <div className="controls">
        <div className="mic-cam">
          <ul className="call-btn-container">
            <li className="call-btn-item">
              <button className="btn" onClick={this.mutemic}>
                <span className="material-icons">
                  {mic ? "mic" : "mic_off"}
                </span>
              </button>
            </li>

            <li className="call-btn-item">
              <button className="btn" onClick={this.mutecamera}>
                <span className="material-icons">
                  {camera ? "videocam" : "videocam_off"}
                </span>
              </button>
            </li>

            <li className="call-btn-item call-endbtn">
              <button className="btn" onClick={this.handleLeaveCall}>
                <span className="material-icons">call_end</span>
              </button>
            </li>
          </ul>
        </div>
       

        <div className="commands">
          <ul className="call-btn-container">
            {/* <li className="call-btn-item">
              <button
                className="btn"
                onClick={() => console.log("Supervisor account clicked")}
              >
                <span className="material-icons">supervisor_account</span>
              </button>
            </li> */}
            <li className="call-btn-item">
              {/* Supervisor account button */}
              <button
                className="btn supervisor-button"
                onClick={this.toggleParticipantsTab}
              >
                <span className="material-icons">supervisor_account</span>
                <span className="notification">{this.props.participantsCount}</span>
              </button>
            </li>
            <li className="call-btn-item">
              <button className="btn" onClick={this.handleScreenShare}>
                <span className="material-icons">screen_share</span>
              </button>
            </li>

            <li className="call-btn-item">
              <button
                className="btn"
                onClick={this.handleChat} // Call the toggleChat function passed from App.js
              >
                <span className="material-icons">chat</span>
              </button>
            </li>
            {/* Recording button */}
            <li className="call-btn-item">
              <button
                className="btn"
                onClick={isRecording ? this.stopRecording : this.startRecording} // Toggle between startRecording and stopRecording based on isRecording state
              >
                <span className="material-icons">
                  {isRecording
                    ? "radio_button_checked"
                    : "radio_button_unchecked"}
                </span>{" "}
                {/* Change icon based on isRecording state */}
              </button>
            </li>

            <li className="call-btn-item">
              <button
                className="btn"
                onClick={() => console.log("More clicked")}
              >
                <span className="material-icons">more_horiz</span>
              </button>
            </li>

            <li className="call-btn-item">
              <button className="btn" onClick={this.toggleEmoticonBar}>
                <span className="material-icons">mood</span>
              </button>
            </li>
            {showEmoticonBar && (
              <EmoticonBar sendEmojiToRemote={this.sendEmojiToRemote} />
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default CallControls;
