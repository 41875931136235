// import React, { useState, useEffect, useRef } from "react";
// import Message from "./message"; // Assuming you have a Message component
// import "./Chat.css"; // Import CSS styles for ChatBoxReact

// const ChatBoxReact = (props) => {
//   const [message, setMessage] = useState("");
//   const [user, setUser] = useState({ uid: 0 });
//   const [imageZoom, setImageZoom] = useState(false);
//   const [selectedImage, setSelectedImage] = useState("");
//   const [isVisible, setIsVisible] = useState(true);

//   const scrollToBottom = () => {
//     const chat = document.getElementById("chatList");
//     chat.scrollTop = chat.scrollHeight;
//   };

//   useEffect(() => {
//     scrollToBottom();
//     setUser({ uid: props.user.uid });
//   }, [props]);

//   const sendMessage = (msg) => {
//     props.sendMessage(msg);
//     scrollToBottom();
//   };

//   const handleSubmit = (event) => {
//     if (message === "") return;
//     event.preventDefault();
//     sendMessage({
//       type: "text",
//       message: {
//         id: user.uid,
//         sender: { uid: user.uid },
//         data: { text: message },
//       },
//     });
//     setMessage("");
//   };

//   const handleChange = (event) => {
//     setMessage(event.target.value);
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === "Enter") {
//       handleSubmit(event);
//     }
//   };

//   const handleCloseChat = () => {
//     setIsVisible(false);
//   };

//   const renderMessage = (userType, data) => {
//     const message = data.message;

//     const msgDiv =
//       data.type === "text" ? (
//         <Message
//           key={data.id}
//           text={message.data.text}
//           time={message.time}
//           imageUrl={null}
//           userType={userType}
//         />
//       ) : (
//         <Message
//           key={data.id}
//           text=""
//           time={message.time}
//           imageUrl={message.data}
//           userType={userType}
//         />
//       );

//     return msgDiv;
//   };

//   //   const showEnlargedImage = (data) => {
//   //     return (
//   //       <img
//   //         src={data}
//   //         style={{
//   //           backgroundColor: 'black',
//   //           position: 'relative',
//   //           zIndex: 100,
//   //           display: 'block',
//   //           cursor: 'pointer',
//   //           marginLeft: 'auto',
//   //           marginRight: 'auto',
//   //           padding: 20,
//   //           borderRadius: 20,
//   //         }}
//   //         onClick={() => setImageZoom(false)}
//   //       />
//   //     );
//   //   };

//   if (!isVisible) {
//     return null; // Render nothing if chat box is not visible
//   }

//   return (
//     <div id="frame">
//       <div className="content">
//         <div className="chat-header">
//           <span className="chat-heading">Chat</span>
//           <span
//             className="material-icons"
//             id="chat-close-btn"
//             onClick={handleCloseChat}
//           >
//             close
//           </span>
//         </div>
//         <div className="messages">
//           <ul className="chat" id="chatList">
//             {props.messages.map((data) => (
//               <div key={data.id}>
//                 {user.uid === data.message.sender.uid
//                   ? renderMessage("sent", data)
//                   : renderMessage("replies", data)}
//               </div>
//             ))}
//           </ul>
//         </div>
//         <div className="message-input">
//           <input
//             className="input"
//             type="text"
//             placeholder="Write your message..."
//             onChange={handleChange}
//             onKeyPress={handleKeyPress}
//             value={message}
//           />
//           <button className="send-msg-btn" type="submit" onClick={handleSubmit}>
//             <i className="fa fa-paper-plane" aria-hidden="true"></i>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatBoxReact;


import React, { useState, useEffect, useRef } from "react";
import Message from "./message"; // Assuming you have a Message component
import "./Chat.css"; // Import CSS styles for ChatBoxReact

const ChatBoxReact = (props) => {
  const [message, setMessage] = useState("");
  const [user, setUser] = useState({ uid: 0 });
  const [imageZoom, setImageZoom] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  const scrollToBottom = () => {
    const chat = document.getElementById("chatList");
    chat.scrollTop = chat.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
    setUser({ uid: props.user.uid });
  }, [props]);

  const sendMessage = (msg) => {
    props.sendMessage(msg);
    scrollToBottom();
  };

  const handleSubmit = (event) => {
    if (message === "") return;
    event.preventDefault();
    sendMessage({
      type: "text",
      message: {
        id: user.uid,
        sender: { uid: user.uid },
        data: { text: message },
      },
    });
    setMessage("");
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleCloseChat = () => {
    // setIsVisible(false);
    props.handleCloseChat();
  };

  const renderMessage = (userType, data) => {
    const message = data.message;

    const msgDiv =
      data.type === "text" ? (
        <Message
          key={data.id}
          text={message.data.text}
          time={message.time}
          imageUrl={null}
          userType={userType}
        />
      ) : (
        <Message
          key={data.id}
          text=""
          time={message.time}
          imageUrl={message.data}
          userType={userType}
        />
      );

    return msgDiv;
  };

  //   const showEnlargedImage = (data) => {
  //     return (
  //       <img
  //         src={data}
  //         style={{
  //           backgroundColor: 'black',
  //           position: 'relative',
  //           zIndex: 100,
  //           display: 'block',
  //           cursor: 'pointer',
  //           marginLeft: 'auto',
  //           marginRight: 'auto',
  //           padding: 20,
  //           borderRadius: 20,
  //         }}
  //         onClick={() => setImageZoom(false)}
  //       />
  //     );
  //   };

  // if (!isVisible) {
  //   return null; // Render nothing if chat box is not visible
  // }

  return (
    <div id="frame">
      <div className="content">
        <div className="chat-header">
          <div className="chat-heading">Chat</div>
          <div
            className="material-icons"
            id="chat-close-btn"
            onClick={handleCloseChat}
          >
            close
          </div>
        </div>
        <div className="messages">
          <ul className="chat" id="chatList">
            {props.messages.map((data) => (
              <div key={data.id}>
                {user.uid === data.message.sender.uid
                  ? renderMessage("sent", data)
                  : renderMessage("replies", data)}
              </div>
            ))}
          </ul>
        </div>
        <div className="message-input">
          <input
            className="input"
            type="text"
            placeholder="Write your message..."
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            value={message}
          />
          <button className="send-msg-btn" type="submit" onClick={handleSubmit}>
            <i className="fa fa-paper-plane" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxReact;
